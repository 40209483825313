<template>
    <Flicking
        :options="{ align: 'center', bound: true, renderOnlyVisible: true }"
    >
        <div
            @click="handleCircleShortsClick(shorts, index)"
            v-for="(shorts, index) in shortsList"
            :key="shorts.content_no"
            class="circle-shorts-container"
        >
            <div
                class="circle-shorts"
                :class="{
                    'circle-shorts-clicked': clickedCircleShorts.has(
                        shorts.content_no,
                    ),
                }"
                :style="{
                    '--circle-shorts-size': props.options.size + 'px',
                }"
                style="user-select: none"
            >
                <div class="circle-shorts-content">
                    <img
                        draggable="false"
                        :src="shorts.select_product_list[0].product_image_url"
                        :alt="shorts.select_product_list[0].product_name"
                    />
                </div>
            </div>
            <div
                class="circle-shorts-text"
                :style="{ maxWidth: props.options.size + 'px' }"
            >
                {{ shorts.select_product_list[0].product_name }}
            </div>
        </div>
    </Flicking>

    <Transition name="fade">
        <div v-if="isModalOpen" class="modal-overlay">
            <button @click="closeModal" class="modal-close-button">
                <i class="ph ph-x" style="flex: 1; align-self: center" />
            </button>
            <Flicking
                ref="shortsCardRef"
                @move-end="onFlickingMove"
                @ready="onFlickingMove"
                style="flex: 1"
                :plugins="flickingPlugins"
                :options="{ renderOnlyVisible: true, defaultIndex: startIndex }"
            >
                <div
                    v-for="shorts in shortsList"
                    :key="shorts.content_no"
                    :id="shorts.content_no"
                    style="user-select: none"
                    class="shorts-card"
                >
                    <HlsBasic
                        :id="shorts.content_no"
                        :src="shorts.ui.content_url"
                        :poster="shorts.ui.thumb_url"
                        muted
                        :controls="false"
                    />
                </div>
            </Flicking>
        </div>
    </Transition>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, onUnmounted } from "vue";
import { useL24Store } from "../store/l24";
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";
import { Perspective } from "@egjs/flicking-plugins";
import HlsBasic from "../components/ui/hlsBasic.vue";

const props = defineProps({
    options: {
        type: Object,
    },
});

const store = useL24Store();
const userNo = ref("");
const userType = ref("");
const clickedCircleShorts = ref(new Set());
const isModalOpen = ref(false);
const shortsCardRef = ref(null);
const startIndex = ref(0);

const flickingPlugins = [new Perspective({ rotate: 0, scale: 0.5 })];

const STORAGE_KEY = "clicked_circle_shorts";

const saveClickedCircleShorts = (contentNo) => {
    if (!clickedCircleShorts.value.has(contentNo)) {
        clickedCircleShorts.value.add(contentNo);
    }

    const clickedData = Array.from(clickedCircleShorts.value).map((id) => ({
        id,
        expiration: new Date().getTime() + 3 * 24 * 60 * 60 * 1000, // 3일 후
    }));
    localStorage.setItem(STORAGE_KEY, JSON.stringify(clickedData));
};

const loadClickedCircleShorts = () => {
    const storedCircleShorts = localStorage.getItem(STORAGE_KEY);
    if (storedCircleShorts) {
        const parsedCircleShorts = JSON.parse(storedCircleShorts);
        const now = new Date().getTime();
        const validCircleShorts = parsedCircleShorts.filter(
            (item) => item.expiration > now,
        );
        clickedCircleShorts.value = new Set(
            validCircleShorts.map((item) => item.id),
        );

        if (validCircleShorts.length !== parsedCircleShorts.length) {
            localStorage.setItem(
                STORAGE_KEY,
                JSON.stringify(validCircleShorts),
            );
        }
    }
};

const handleCircleShortsClick = (shorts, index) => {
    startIndex.value = index;
    openModal();
};

const initSetUp = async () => {
    await store.getL24Config();
    if (store.l24config) {
        userNo.value = store.l24config.user_no;
        userType.value = store.l24config.type;
    } else {
        userNo.value = props.options.userNo;
        userType.value = props.options.type;
    }

    const params = {
        row_count: 10,
        order_col: "view_cnt",
        cache_time: 5,
        user_no: userNo.value,
        type: "shorts",
        have_product: "Y",
        only_shortform: "Y",
    };
    await store.getShortsList(params);

    loadClickedCircleShorts();
};

const closeModal = () => {
    isModalOpen.value = false;
    removeVideoEventListeners();
};

const openModal = () => {
    isModalOpen.value = true;
};

const extractId = (htmlString) => {
    const match = htmlString.match(/id="(\d+)"/);
    return match ? match[1] : null;
};

const onVideoEnded = () => {
    if (shortsCardRef.value) {
        shortsCardRef.value.next();
    }
};

const removeVideoEventListeners = () => {
    const allVideos = document.querySelectorAll("video");
    allVideos.forEach((video) => {
        video.removeEventListener("ended", onVideoEnded);
    });
};

const onFlickingMove = () => {
    const panelStatus = shortsCardRef.value.getStatus({
        includePanelHTML: true,
    });

    const allVideos = shortsCardRef.value.$el.querySelectorAll("video");
    allVideos.forEach((video) => {
        video.pause();
    });

    removeVideoEventListeners();

    panelStatus.panels.forEach((panel, index) => {
        if (index === panelStatus.position.panel) {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = panel.html;

            const video = tempDiv.querySelector("video");
            if (video) {
                const actualVideo = shortsCardRef.value.$el.querySelector(
                    `video[src="${video.src}"]`,
                );
                if (actualVideo) {
                    setTimeout(() => {
                        actualVideo.play();
                    }, 100);
                    // 새 비디오에 'ended' 이벤트 리스너 추가
                    actualVideo.addEventListener("ended", onVideoEnded);
                }
            }

            tempDiv.remove();
        }
    });

    const currentPanel = panelStatus.panels[panelStatus.position.panel];
    const contentNo = extractId(currentPanel.html);
    saveClickedCircleShorts(contentNo);
};

const shortsList = computed(() => store.shorts);

onMounted(async () => {
    initSetUp();
    await nextTick();
});

onUnmounted(() => {
    removeVideoEventListeners();
});
</script>

<style scoped>
button,
input[type="submit"],
input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.circle-shorts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    width: calc(var(--circle-shorts-size) + 2 * var(--border-thickness));
}

.circle-shorts {
    --circle-shorts-size: 60px;
    --border-thickness: 3px;
    --white-border-thickness: 3px;

    flex: 0 0 auto;
    width: calc(var(--circle-shorts-size) + 2 * var(--border-thickness));
    height: calc(var(--circle-shorts-size) + 2 * var(--border-thickness));
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    padding: var(--border-thickness);
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}

.circle-shorts::before {
    content: "";
    position: absolute;
    top: var(--border-thickness);
    left: var(--border-thickness);
    right: var(--border-thickness);
    bottom: var(--border-thickness);
    border-radius: 50%;
    background: white;
}

.circle-shorts.circle-shorts-clicked {
    background: #f0f0f0;
}

.circle-shorts-text {
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.circle-shorts-content {
    position: absolute;
    top: calc(var(--border-thickness) + var(--white-border-thickness));
    left: calc(var(--border-thickness) + var(--white-border-thickness));
    right: calc(var(--border-thickness) + var(--white-border-thickness));
    bottom: calc(var(--border-thickness) + var(--white-border-thickness));
    border-radius: 50%;
    overflow: hidden;
}

.circle-shorts-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 1000;
}

.modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    display: flex;
}

.modal-close-button:hover {
    background: #f0f0f0;
}

.shorts-card {
    background: black;
    border-radius: 10px;
    width: calc(100vw - 40px);
    max-width: 400px;
    aspect-ratio: 9 / 16;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
