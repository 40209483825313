<template>
    <div class="hls-player">
        <div class="poster-overlay" :class="{ 'fade-out': isLoaded }"></div>
        <video
            ref="videoElement"
            :muted="muted"
            :loop="loop"
            :autoplay="autoplay"
            :controls="controls"
            :preload="preload"
            :poster="poster"
            playsinline
        ></video>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import Hls from "hls.js";

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    muted: Boolean,
    loop: Boolean,
    autoplay: Boolean,
    controls: {
        type: Boolean,
        default: true,
    },
    preload: {
        type: String,
        default: "auto",
    },
    poster: String,
});

const videoElement = ref(null);
const isLoaded = ref(false);
let hls = null;

const initPlayer = (src) => {
    if (!videoElement.value) return;

    if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(videoElement.value);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
            isLoaded.value = true;
            if (props.autoplay) videoElement.value.play();
        });
    } else if (
        videoElement.value.canPlayType("application/vnd.apple.mpegurl")
    ) {
        videoElement.value.src = src;
        videoElement.value.addEventListener("loadedmetadata", () => {
            isLoaded.value = true;
            if (props.autoplay) videoElement.value.play();
        });
    }
};

const destroyPlayer = () => {
    if (hls) {
        hls.destroy();
        hls = null;
    }
    isLoaded.value = false;
};

onMounted(() => {
    initPlayer(props.src);
});

onBeforeUnmount(() => {
    destroyPlayer();
});

watch(
    () => props.src,
    (newSrc) => {
        destroyPlayer();
        initPlayer(newSrc);
    },
);
</script>

<style scoped>
.hls-player {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.poster-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: v-bind("`url(${poster})`");
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease;
    opacity: 1;
}

.poster-overlay.fade-out {
    opacity: 0;
}

video {
    width: 100%;
    height: auto;
}
</style>
